import Cookies from 'js-cookie';
import { Language, CookieNames } from 'config/constants';

export const BASE_URL = '/api/v1';
export const PROJECT_TOKEN = 'f28aae85-72b1-420d-8b16-37c8ab519cf4';
export const X_DEVICE_BY = 'web/1.0.0';
export const CONTENT_TYPE = 'application/json';

export enum Entity {
    auth = 'auth',
    profile = 'profile',
    text = 'text',
    likes = 'likes',
    search = 'search',
    profiles = 'profiles',
    feedback = 'feedback',
    chat = 'profile-chats',
    message = 'message',
    config = 'config',
}

interface IDefaultHeaders {
    'Content-Type'?: string
    'Accept'?: string
    'Project-Token': string
    'x-device-by': string
}

interface IDynamicHeaders {
    Authorization: string
    Language: string
}

export const getDefaultHeaders = (isFormData?:boolean):IDefaultHeaders => {
    const headers:IDefaultHeaders = {
        [CookieNames.Accept]: CONTENT_TYPE,
        [CookieNames.ProjectToken]: PROJECT_TOKEN,
        [CookieNames.Device]: X_DEVICE_BY,
    }

    if (!isFormData) {
        headers[CookieNames.ContentType] = CONTENT_TYPE;
    }

    return headers;
}

export const getDynamicHeaders = ():IDynamicHeaders => {
    const authorization = Cookies.get(CookieNames.AuthToken) || '';
    const language = Cookies.get(CookieNames.Language) || Language.Ru;

    return {
        Authorization: authorization,
        Language: language,
    }
}



