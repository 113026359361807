import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { ICityList, ISearch } from './types';

export const searchAPI = createApi({
    reducerPath: 'search',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        loadCities: build.query<ICityList, ISearch>({
            query: (payload) => {
                const cityParam = payload.q ? `q=${payload.q}&` : ``;
                const excludeParam = payload.exclude ? `exclude=${payload.exclude}` : ``;
                return {
                    url: `${Entity.search}/citylist?${cityParam}${excludeParam}`,
                    headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
                }
            },
        })
    }),
});