import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { errorSlice, errorMiddleware } from './error/error';
import {
    authAPI, profileAPI, galleryAPI, usersAPI, searchAPI,
    textAPI, feedbackAPI, chatAPI, configApi,
} from 'api';

type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
    reducer: {
        [errorSlice.name]: errorSlice.reducer,
        [authAPI.reducerPath]: authAPI.reducer,
        [profileAPI.reducerPath]: profileAPI.reducer,
        [galleryAPI.reducerPath]: galleryAPI.reducer,
        [usersAPI.reducerPath]: usersAPI.reducer,
        [searchAPI.reducerPath]: searchAPI.reducer,
        [textAPI.reducerPath]: textAPI.reducer,
        [feedbackAPI.reducerPath]: feedbackAPI.reducer,
        [chatAPI.reducerPath]: chatAPI.reducer,
        [configApi.reducerPath]: configApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        errorMiddleware,
        authAPI.middleware,
        profileAPI.middleware,
        galleryAPI.middleware,
        usersAPI.middleware,
        searchAPI.middleware,
        textAPI.middleware,
        feedbackAPI.middleware,
        chatAPI.middleware,
        configApi.middleware,
    ),
});

export const useAppSelector:TypedUseSelectorHook<RootState> = useSelector;
