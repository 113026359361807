import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Path } from 'routes';

export const useTitle = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    switch (pathname) {
        case Path.public.main:
            return t('title.header_main');
        case Path.public.feedback:
            return t('title.header_feedback');
        case Path.public.faq:
            return t('title.header_faq');
        case Path.public.offer:
            return t('title.header_offer');
        case Path.private.profile:
            return t('title.header_profile');
        case Path.private.settings:
            return t('title.header_settings');
        case Path.private.registration:
            return t('title.header_registration');
        case Path.private.gallery:
            return t('title.header_gallery');
        case Path.private.dialogs:
            return t('title.header_dialogs');
        case Path.private.blocklist:
            return t('title.header_blocklist');
        default:
            return t('title.header_main');
    }
};
