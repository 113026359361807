import React from 'react';
import Pages from 'pages';
import { Header } from 'components/Header/Header';
import { Menu } from 'components/Menu/Menu';
import { Component } from './App.styled';

const App = () => (
    <Component id="app">
        <Header/>
        <Menu/>
        <Pages/>
    </Component>
);

export default App;
